<template>
	<ion-page>
		<spot-header :title="pageTitle" :canGoHome="true" :canGoBack="false" @pressedGoHome="$router.push('/home')" @pressedGoBack="$router.push('/home')"></spot-header>

		<ion-content :fullscreen="true"> <!--v-if="isActive"-->
			<ion-progress-bar type="indeterminate" v-if="!isActive"></ion-progress-bar>
			<div id="actionArea" v-else>
				<web-socket-reader crReplacedChar="|" @readedBarcode="onReadedBarcode" @readerError="onReaderError"></web-socket-reader>
				<spot-select-custom
					:ref="projectField.name"
					:label="projectField.text"
					:allignLabel="projectField.allignLabel"
					:required="projectField.required"
					:placeholder="getPlaceHolder(projectField)"
					:enabled="getEnabled(projectField)"
					:value="projectField.value"
					:options="tablesData.projects"
					:optionField="projectField.refField"
					:validationState="projectField.validationState"
					:canAddItem="projectField.canAddItem"
					:canRefreshList="projectField.canRefresh"
					:canUnlock="projectField.canUnlock"
					:custom-label="customProject"
					:selectedLabel="selectedText"
					:deselectLabel="deselectText"
					:selectLabel="selectText"
					@selected="assignedProject"
					@unselected="resetProject">
				</spot-select-custom>
				<spot-select-custom
					:ref="documentField.name"
					:label="documentField.text"
					:allignLabel="documentField.allignLabel"
					:required="documentField.required"
					:placeholder="getPlaceHolder(documentField)"
					:enabled="getEnabled(documentField)"
					:value="documentField.value"
					:options="tablesData.documents"
					:optionField="documentField.refField"
					:validationState="documentField.validationState"
					:canAddItem="documentField.canAddItem"
					:canRefreshList="documentField.canRefresh"
					:canUnlock="documentField.canUnlock"
					:custom-label="customDocument"
					:selectedLabel="selectedText"
					:deselectLabel="deselectText"
					:selectLabel="selectText"
					@selected="assignedDocument"
					@unselected="resetDocument">
				</spot-select-custom>
				<spot-select
					:ref="referenceItemField.name"
					:label="referenceItemField.text"
					:allignLabel="referenceItemField.allignLabel"
					:required="referenceItemField.required"
					:placeholder="getPlaceHolder(referenceItemField)"
					:enabled="getEnabled(referenceItemField)"
					:value="referenceItemField.value"
					:options="tablesData.referenceItems"
					:optionField="referenceItemField.refField"
					:validationState="referenceItemField.validationState"
					:canAddItem="referenceItemField.canAddItem"
					:canRefreshList="referenceItemField.canRefresh"
					:canUnlock="referenceItemField.canUnlock"
					:selectedLabel="selectedText"
					:deselectLabel="deselectText"
					:selectLabel="selectText"
					@selected="assignedReferenceItem"
					@unselected="resetReferenceItem">
				</spot-select>
				<spot-button
					v-if="settings.printerManagement == true"
					:ref="printButton.name"
					:text="printButton.text"
					:enabled="getEnabled(printButton)"
					:size="printButton.size"
					:fill="printButton.fill"
					:icon="printButton.icon"
					:expand="printButton.expand"
					:color="printButton.color"
					@clicked="openModal(false)">
				</spot-button>
				<spot-button
					:ref="confirmButton.name"
					:text="confirmButton.text"
					:enabled="getEnabled(confirmButton)"
					:size="confirmButton.size"
					:fill="confirmButton.fill"
					:icon="confirmButton.icon"
					:expand="confirmButton.expand"
					:color="confirmButton.color"
					@clicked="manageProject">
				</spot-button>
				<ion-modal ref="modal" backdrop-dismiss="false" :is-open="isOpenModal" @didDismiss="modalDismissed">
					<ion-content>
						<ion-toolbar>
							<ion-title> {{ modalTitle }}</ion-title>
						</ion-toolbar>
						<ion-card disabled="false">
							<ion-card-content class="flex-column-left-container">
								<div lines="none" v-for="field in itemContents" :key="field.id" class="no-pad">
									<spot-input
										v-if="(field.inputType == 'text' || field.inputType == 'number')"
										:ref="field.name"
										:label="field.text"
										:required="field.required"
										:placeholder="getPlaceHolder(field)"
										:enabled="field.enabled"
										:validationState="field.validationState"
										:furtherInfo="field.furtherInfo"
										:value="field.value"
										:type="field.inputType"
										@textChanged="changedTextField(field.id, $event)">
									</spot-input>
								</div>
							</ion-card-content>
						</ion-card>
						<div class="flex-row-center-container">
							<spot-button
								fill="outline"
								size="large"
								color="primary"
								:enabled="true"
								:expand="null"
								:text="undoText"
								@clicked="closeModal(false)">
							</spot-button>
							<spot-button
								fill="outline"
								size="large"
								color="primary"
								:enabled="!!itemContents[itemContent.labelQuantity].value"
								:expand="null"
								:text="confirmText"
								@clicked="closeModal(true)">
							</spot-button>
						</div>
					</ion-content>
				</ion-modal>
			</div>
			<div id="debugArea" style="color:red;" v-if="settings.debug">
				<div>Test Area</div>
				<div v-for="field in fields" :key="field.id">
					{{ field.name }} = {{ field.value }}
				</div>
			</div>
		</ion-content>

		<spot-footer></spot-footer>
	</ion-page>
</template>

<style scoped>
	ion-modal {
		--height: 40%;
		--width: 80%;
		--border-radius: 16px;
		--box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
	}
	ion-modal::part(backdrop) {
		background: var(--ion-color-medium);
		opacity: 1;
	}
	ion-modal ion-toolbar {
		--background: var(--ion-color-primary);
		--color: white;
	}
	.flex-column-left-container {
		margin-top: 0.2em;
		margin-bottom: 0.2em;
		text-align: left;
		display: flex;
		flex-direction: column;
		/*justify-content: left;*/
		/*align-items: left;*/
		flex-wrap: wrap;
	}
	.flex-row-center-container {
		margin-top: 0.2em;
		text-align: center;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
</style>

<script>
	import { IonContent, IonPage, IonProgressBar, IonCard, IonCardContent, IonModal, IonTitle, IonToolbar} from '@ionic/vue';
	import { defineComponent } from 'vue';
	import SpotButton from '../components/SpotButton.vue';
	import SpotSelect from '../components/SpotSelect.vue';
	import SpotSelectCustom from '../components/SpotSelectCustom.vue';
	import SpotInput from '../components/SpotInput.vue';
	import SpotFooter from "../components/SpotFooter.vue";
	import SpotHeader from "../components/SpotHeader.vue";
	import WebSocketReader from '../components/WebSocketReader.vue';
	import { checkUserSession, confirmYesNo,/* insertValue, */ settings, showMsgError, showMsgInfo, showMsgWarning, writeLog } from '../plugins/common.js';
	import { getPrinter, createPackage, executePrinterAction, searchDocuments, searchPrinter, searchProjects, searchProjectTypology, searchReferenceItems, tablesData/*, updateProject */} from '../plugins/customDatabase.js';

	export default defineComponent({
		name: "SelectProjectItem",
		props: {
			pageTitle: { type: String, default: 'Preparazione Colli' }, //'Seleziona Progetto - Articolo'
			pickingListTitle: { type: String, default: 'placeholder.picking_list' },
			selectedText: { type: String, default: 'Selezionato' },
			deselectText: { type: String, default: 'annulla' },
			selectText: { type: String, default: 'seleziona' },
			// noOrdersText: { type: String, default: 'Nessun Ordine presente' },
			// newOrderText: { type: String, default: 'Nuovo Ordine' },
			// editOrderText: { type: String, default: 'Modifica Ordine'}, //'Modifica Ordine: '
			// showOrderText: { type: String, default: 'Visualizza Ordine'}, //'Visualizza Ordine: '
			undoText: { type: String, default: 'Annulla' },
			confirmText: { type: String, default: 'Conferma' },
			// unselected: { type: String, default: 'Nessuno' },
			messageSearchProjectTitle: { type: String, default: 'Ricerca Progetto' },
			messageSearchProjectNotFound: { type: String, default: 'Progetto non trovato!' },
			// messageTitleCheckTAG: { type: String, default: 'Controllo TAG' },
			// warnMultipleTAG: { type: String, default: 'Letto più di un TAG. Avvicinarsi solo al TAG da leggere e riprovare' },
			// warnInavlidTAG: { type: String, default: 'TAG letto solo in parte. Riprovare a leggere' },
			messageTitleReader: { type: String, default: 'Lettura Barcode' },
			errorReadingReader: { type: String, default: 'Errore nella lettura del barcode' },
			// confirmDeleteTitle: { type: String, default: 'Conferma Cancellazione' },
			// confirmDeleteMessage: { type: String, default: 'Confermi la cancellazione dell\'ordine corrente?' },
			successAddPackageMessage: { type: String, default: "Nuovi colli inseriti a sistema." },
			messageTitlePrint: { type: String, default: "Stampa Etichetta" },
			//successPrintMessage: { type: String, default: "Stampa etichetta avvenuta correttamente. Etichetta: " },
			successPrintMessage: { type: String, default: "Stampa etichetta avvenuta correttamente. Etichette stampate: " },
			errorPrintMessage: { type: String, default: "Errore durante la stampa dell'etichetta" },
			// errorPrintConfirmMessage: { type: String, default: "Confermi di procedere lo stesso anche se la stampa non è avvenuta (l'etichetta non potrà essere stampata successivamente)?" },
			warningPrinterOffline: { type: String, default: "La stampante non è raggiungibile; l'etichetta non è stata stampata" },
			warningPrintConfirmMessage: { type: String, default: "Confermi di procedere lo stesso anche se non è stata assegnata una stampante (l'etichetta non verrà stampata)?" },
		},
		components: {
			IonPage,
			IonContent,
			IonProgressBar,
			IonCard,
			IonCardContent,
			IonModal,
			IonTitle,
			IonToolbar,
			SpotHeader,
			SpotFooter,
			SpotSelect,
			SpotSelectCustom,
			SpotInput,
			SpotButton,
			WebSocketReader,
		},
		data: () => ({
			isActive: null,
			field: {
				project: 0,
				document: 1,
				referenceItem: 2,
				print: 3,
				confirm: 4,
			},
			fields: [
				{ id: 0, name: 'project', text: 'Ordine', enabled: null, allignLabel: true, placeholder: 'Seleziona Ordine', emptyValue: 'Nessuno', inputType: 'combo', defaultValue: null, value: null, required: true, refField: 'name', validationState: null, canAddItem: false, canRefresh: false, canUnlock: false },
				{ id: 1, name: 'document', text: 'filtro', enabled: null, allignLabel: true, placeholder: 'Seleziona filtro', emptyValue: 'Nessuno', inputType: 'combo', defaultValue: null, value: null, required: true, refField: 'name', validationState: null, canAddItem: false, canRefresh: false, canUnlock: false },
				{ id: 2, name: 'referenceItem', text: 'Zona', enabled: null, allignLabel: true, placeholder: 'Seleziona Zona', emptyValue: 'Nessuno', inputType: 'select', defaultValue: null, value: null, required: true, refField: 'name', validationState: null, canAddItem: false, canRefresh: false, canUnlock: false },
				{ id: 3, name: 'print', text: 'Stampa Etichette', enabled: null, inputType: 'button', size: 'default', fill: 'solid', expand: 'block', icon: 'print', color: 'primary' },
				{ id: 4, name: 'confirm', text: 'Procedi', enabled: null, inputType: 'button', size: 'default', fill: 'solid', expand: 'block', icon: 'bagAdd', color: 'primary' },
			],
			itemContent: {
				labelQuantity: 0,
			},
			itemContents: [
				{ id: 0, name: 'labelQuantity', text: 'Nr. Etichette', enabled: true, placeholder: 'Inserisci Etichette', inputType: 'number',  defaultValue: null, value: null, required: true, furtherInfo: null, validationState: null, canAddItem: false, canRefresh: false, canUnlock: false },
			],
			isOpenModal: false,
			modalTitle: "Etichette da Stampare",
			modalReadOnly: null,
			// currentFieldId: 0,
			lastBarcode: null,
			isIncoming: null,
			isOutgoing: null,
			isPicking: null,
			isRevision: null,
			isLocked: null,
			printerId: null,
			// duplicate,
			// enter,
			// reader,
			// trash,
			// brush,
			settings,
			checkUserSession,
			confirmYesNo,
			showMsgError,
			showMsgInfo,
			showMsgWarning,
			writeLog,
			searchProjectTypology,
			searchProjects,
			searchDocuments,
			searchReferenceItems,
			searchPrinter,
			createPackage,
			getPrinter,
			executePrinterAction,
			// updateProject,
			tablesData,
		}),
		computed: {
			// currField() { return this.fields[this.currentFieldId] },
			projectField() { return this.fields[this.field.project] },
			documentField() { return this.fields[this.field.document] },
			referenceItemField() { return this.fields[this.field.referenceItem] },
			printButton() { return this.fields[this.field.print] },
			confirmButton() { return this.fields[this.field.confirm] },
			isEmptyRequiredFields() {
				let ret = false;
				this.fields.forEach(element => {if (element.required && !element.value) ret=true});
				return ret;
			},
			canPrint() {
				return (this.settings.printerManagement && (this.printerId != null))
			}
		},
		async ionViewWillEnter() {
			this.getUrlParamsAndQuery();
			this.setDefault();
			await this.getAllTablesData();
			this.isActive = true;
		},
		ionViewWillLeave() {
			this.isOpenModal = false;
			this.isActive=false;
		},
		methods: {
			// Settings & StartUp
			getUrlParamsAndQuery() {
				this.isIncoming = (/true/i).test(this.$route.query.incoming);
				this.isOutgoing = (/true/i).test(this.$route.query.outgoing);
				this.isPicking = (/true/i).test(this.$route.query.picking);
				this.isRevision = (/true/i).test(this.$route.query.revision);
				this.isLocked = (/true/i).test(this.$route.query.locked);
			},
			setDefault() {
				this.fields.forEach(element => element.value = element.defaultValue);
				this.fields.forEach(element => (element.required == true? (element.value == null ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.isOpenModal = false;
				this.modalReadOnly = null;
				this.lastBarcode = null;
			},
			getPlaceHolder(element) {
				return ((this.settings.showFieldsPlaceholder) ? (element.required ? element.placeholder : this.unselected ) : '')
			},
			getEnabled(element) {
				let ret = false;
				// if(this.isActive == true) {
				if (element.enabled != null) ret = element.enabled;
				else {
					switch (element.id) {
						// case this.field.project:
						case this.field.project:
							ret = !!tablesData.projects
							break;
						case this.field.document:
							ret = !!this.projectField.value
							break;
						case this.field.referenceItem:
							ret = !!this.projectField.value && !! this.documentField.value
							break;
						case this.field.print:
							ret = this.canPrint && !!this.projectField.value
							break;
						case this.field.confirm:
							// ret = !!this.projectField.value && !! this.documentField.value && this.referenceItemField.value
							ret = !this.isEmptyRequiredFields
							break;
					}
				}
				// }
				return ret
			},
			async getCustomLabel(element) {
				let ret = null;
				// if(this.isActive == true) {
					switch (element.id) {
						case this.field.project:
							ret = this.customProject
							break;
						case this.field.document:
							ret = this.customDocument
							break;
						// case this.field.referenceItem:
						// case this.field.confirm:
					}
				// }
				return ret
			},
			// Database
			async getAllTablesData() {
				this.tablesData.projects = [];
				this.tablesData.currentProject = null;
				this.tablesData.documents = [];
				this.tablesData.currentDocument = null;
				this.tablesData.referenceItems = [];
				this.tablesData.currentReferenceItem = null;
				this.tablesData.currentProjectTypology = null;
				if (this.isIncoming == true) await this.searchProjectTypology(this.supplierOrderTitle);
				if (this.isOutgoing == true) await this.searchProjectTypology(this.customerOrderTitle);
				if (this.isPicking == true) await this.searchProjectTypology(this.pickingListTitle);
				await this.searchProjects(this.tablesData.currentProjectTypology.id);
				if (this.settings.printerManagement == true) {
					this.tablesData.printers = [];
					this.tablesData.currentPrinter = null;
					await this.searchPrinter(true);
					if (!this.tablesData.currentPrinter || this.tablesData.currentPrinter.length == 0) {
						this.printerId = null;
						this.confirmYesNo(this.messageTitlePrint, this.warningPrintConfirmMessage)
						.then((data) => {
							if (data == false) this.$router.push("/home");
						})
					}
					else {
						this.printerId = this.tablesData.currentPrinter[0].id;
					}
				}
			},
			customProject ({ name, customer }) {
				return name + " - " + (!customer ? "" : customer.name)
			},
			customDocument ({ name, issued_at }) {
				let issuedDate = new Date(issued_at).toLocaleDateString()
				return name + " DEL " + issuedDate
			},
			// Actions
			async onReadedBarcode(barcode,type) {
				this.lastBarcode = barcode;
				this.writeLog(barcode, type);
				await this.committedBarcode();
			},
			onReaderError(data,error) {
				this.showMsgError(data, error);
				this.showMsgWarning(this.messageTitleReader, this.errorReadingReader);
			},
			openModal(isReadOnly) {
				this.modalReadOnly = isReadOnly;
				this.isOpenModal = true;
			},
			async closeModal(saveRecord) {
				if (saveRecord == true) await this.addPackageAndPrintLabel(this.itemContents[this.itemContent.labelQuantity].value);
				this.isOpenModal = false;
			},
			async modalDismissed() {
				this.itemContents[this.itemContent.labelQuantity].value = this.itemContents[this.itemContent.labelQuantity].defaultValue;
			},
			changedTextField(fieldId, value) {
				this.itemContents[fieldId].value = value;
				(!value || value.length == 0)? this.itemContents[fieldId].validationState = false : this.itemContents[fieldId].validationState = true
			},
			async assignedProject(value) {
				this.tablesData.currentProject = value;
				await this.searchDocuments(this.tablesData.currentProject.id);
				this.projectField.value = value;
				this.projectField.validationState = true;
				this.resetDocument();
				this.resetReferenceItem();
			},
			resetProject() {
				this.projectField.value = this.projectField.defaultValue;
				this.tablesData.currentProject = null;
				this.projectField.validationState = false;
				this.resetDocument();
				this.resetReferenceItem();
			},
			async assignedDocument(value) {
				this.tablesData.currentDocument = value;
				await this.searchReferenceItems(this.tablesData.currentProject.id, this.tablesData.currentDocument.id);
				this.documentField.value = value;
				this.documentField.validationState = true;
				this.resetReferenceItem();
			},
			resetDocument() {
				this.documentField.value = this.documentField.defaultValue;
				this.tablesData.currentDocument = null;
				this.documentField.validationState = false;
				this.resetReferenceItem();
			},
			async assignedReferenceItem(value) {
				this.tablesData.currentReferenceItem = value;
				this.referenceItemField.value = value;
				this.referenceItemField.validationState = true;
			},
			resetReferenceItem() {
				this.referenceItemField.value = this.documentField.defaultValue;
				this.tablesData.currentReferenceItem = null;
				this.referenceItemField.validationState = false;
			},
			manageProject() {
				this.$router.push("/management/" + this.tablesData.currentProject.id + 
					"?incoming="+this.isIncoming+
					"&outgoing="+this.isOutgoing+
					"&picking="+this.isPicking+
					"&revision="+this.isRevision+
					"&locked="+this.isLocked+
					"&document="+this.tablesData.currentReferenceItem.id
				);
			},
			async addPackageAndPrintLabel(labelQty) {
				let success = true;
				for (let i = 0, l = labelQty; i < l; i++) {
					let addPackageState = await this.addPackage();
					if (addPackageState && this.canPrint) {
						await this.getPrinter(this.printerId) //this.printerId
						if (this.tablesData.currentPrinter.is_online == true) {
							this.executePrinterAction(`printers/` + this.printerId + `?do=print_single_barcode` + `&barcode=` + this.tablesData.currentPackage.name)
							.then(() => {})
							.catch(error => {
								success = false;
								this.checkUserSession(error, "warning");
								this.showMsgError(this.messageTitlePrint, this.errorPrintMessage);
							})
						}
						else {
							success = false;
							this.showMsgWarning(
								this.messageTitlePrint,
								this.warningPrinterOffline);
						}
					}
				}
				if (success == true) {
					if (this.canPrint) {
						this.showMsgInfo(
							this.messageTitleInsert,
							this.successAddPackageMessage + "<br><br>" + this.successPrintMessage + "&nbsp" + labelQty);
					}
					else {
						this.showMsgInfo(
							this.messageTitleInsert,
							this.successAddPackageMessage);
					}
				}
			},
			async addPackage() {
				this.tablesData.currentPackage = null;
				await this.createPackage(this.prepareNewPackage());
				if (this.tablesData.currentPackage != null) return true; else return false;
			},
			prepareNewPackage() {
				let myRecord = {
					packages: {
						project_id: this.tablesData.currentProject.id,
						closed: false,
						shipped: false,
					}
				};
				return myRecord;
			},
			async committedBarcode() {
				let foundElement = null;
				this.tablesData.projects.some(element => {
					if (element.name == this.lastBarcode) {
						foundElement = element;
						return true;
					}
				});
				if (!foundElement) this.showMsgWarning(this.messageSearchProjectTitle, this.messageSearchProjectNotFound);
				else await this.assignedProject(foundElement);
			},
		},
	});
</script>