import { createRouter, createWebHistory } from '@ionic/vue-router';
import HomePage from '../views/HomePage.vue';
import Incoming from '../views/IncomingItems.vue';
import Login from "../views/LogIn.vue";
import Movement from '../views/MoveItems.vue';
import Outgoing from '../views/OutgoingItems.vue';
import Root from "../views/RootActions.vue";
import SelectOrder from '../views/SelectOrder.vue';
import SelectProject from '../views/SelectProject.vue'; // '../views/SelectOrder.vue'
import ManageProject from '../views/ManageProject.vue'; // '../views/ManageOrder.vue'
import SelectProjectForPackages from '../views/SelectProjectForPackages.vue';
import ManagePackages from '../views/ManagePackages.vue';
import ManagePackage from '../views/ManagePackage.vue';

const routes = [
	{
		path: "/",
		name: "Root",
		component: Root,
	},
	{
		path: "/login",
		name: "LogIn",
		component: Login,
	},
	{
		path: '/home',
		name: 'Home',
		component: HomePage
	},
	{
		path: '/order',
		name: 'SelectOrder',
		component: SelectOrder
	},
	{
		path: '/selection',
		name: 'SelectProject',
		component: SelectProject
	},
	{
		path: '/management/:id',
		name: 'ManageProjectId',
		component: ManageProject
	},
	{
		path: '/package',
		name: 'SelectProjectForPackages',
		component: SelectProjectForPackages
	},
	{
		path: '/package/:id',
		name: 'ManagePackage',
		component: ManagePackage
	},
	{
		path: '/packages/:id',
		name: 'ManagePackages',
		component: ManagePackages
	},
	{
		path: '/incoming',
		name: 'IncomingItems',
		component: Incoming
	},
	{
		path: '/incoming/:id',
		name: 'IncomingItemsId',
		component: Incoming,
	},
	{
		path: '/outgoing',
		name: 'OutgoingItems',
		component: Outgoing
	},
	{
		path: '/outgoing/:id',
		name: 'OutgoingItemsId',
		component: Outgoing
	},
	{
		path: '/movement',
		name: 'MoveItems',
		component: Movement
	},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
