<template>
	<div class="label" v-if="!allignLabel && field.label">
		<ion-label>{{ field.label }}</ion-label>
	</div>
	<div :class="{input: !allignLabel, inputLabel: allignLabel}">
		<ion-label class="ion-align-self-center" v-if="allignLabel && field.label">{{ field.label }}</ion-label>
		<!-- TODO: add (inputmode, pattern, min, max, minlength, maxlength), implement (required with required, name with label) -->
		<ion-input
			:class="{defaultBorder: validationState == null || !enabled, invalidBorder: enabled && validationState == false, validBorder: enabled && validationState == true}"
			v-model="field.value"
			ref="input"
			:placeholder="placeholder"
			:disabled="!field.enabled"
			:type="type"
			:clear-input="clearInput"
			@ionChange="detectTextChange"
			@keypress="detectEnter($event.key)">
		</ion-input>
		<ion-button class="ion-align-self-center" slot="end" fill="clear" v-if="canUnlock" @click="changeLockState">
			<ion-icon slot="icon-only" v-if="!field.enabled" :icon="lockOpenOutline"></ion-icon>
			<ion-icon slot="icon-only" v-else :icon="lockClosedOutline"></ion-icon>
		</ion-button>
		<ion-label class="ion-align-self-center" v-if="furtherInfo">{{ furtherInfo }}</ion-label>
	</div>
</template>

<style scoped>
	* {
		--padding-bottom:	0px;
		--padding-top:	0px;
		/*margin-right: .5em;*/
	}
	ion-item {
		--padding-start: 0;
		--padding-end: 0;
	}
	ion-icon {
		margin-right: 0;
	}
	ion-button {
		--padding-start: 0;
		--padding-end: 0;
	}
	ion-label {
		font-weight: bold;
		/*margin-top: .7em;*/
	}
	ion-input {
		border: 1px solid var(--ion-color-light-shade);
		border-radius: 5px !important;
	}
	.defaultBorder {
		border: 1px solid var(--ion-color-light-shade) !important;
	}
	.invalidBorder {
		border: 1px solid red !important;
	}
	.validBorder {
		border: 1px solid green !important;
	}
	.label {
		margin-top: .5em;
		margin-left: .5em;
	}
	.input {
		display: flex; /*inline-block;*/
		flex-direction: row;
		vertical-align: baseline;
		text-align: left;
		margin-left: .5em;
		margin-right: .5em;
	}
	.inputLabel {
		display: flex; /*inline-block;*/
		flex-direction: row;
		vertical-align: baseline;
		text-align: left;
		margin-top: .5em;
		margin-left: .5em;
		margin-right: .5em;
	}
</style>

<script>
import { IonButton, IonIcon, IonInput, IonLabel } from '@ionic/vue';
import { lockClosedOutline, lockOpenOutline } from "ionicons/icons";
import { defineComponent } from "vue";

	export default defineComponent({
		props: {
			label: { type: String, default: "Label" },
			allignLabel: { type: Boolean, default: false },
			required: { type: Boolean, default: true },
			value: { /*type: String, */ default: null },
			furtherInfo: { type: String, default: null },
			placeholder: { type: String, default: "Insert Data" },
			enabled: { type: Boolean, default: true },
			type: { type: String, default: "text" },
			clearInput: { type: Boolean, default: true },
			validationState: { type: Boolean, default: null },
			//TODO: add (inputmode, pattern, min, max, minlength, maxlength), implement (required with required, name with label)
			canUnlock: { type: Boolean, default: false },
		},
		components: {
			IonLabel,
			IonInput,
			IonButton,
			IonIcon,
		},
		emits: [ "textChanged", "committed" ],
		data: () => ({
			field: {
				label: null,
				value: null,
				enabled: true,
			},
			lockOpenOutline,
			lockClosedOutline,
		}),
		mounted () {
			this.field.label = (!this.label) ? this.label : ((!this.required) ? this.label : this.label + "*");
			this.field.value = this.value;
			this.field.enabled = this.enabled;
		},
		watch: {
			label () {
				this.field.label = (!this.label) ? this.label : ((!this.required) ? this.label : this.label + "*");
			},
			value () {
				this.field.value = this.value;
			},
			enabled () {
				this.field.enabled = this.enabled;
			},
  },
		methods: {
			detectEnter(key) {
				if (key == "Enter") {
					this.field.enabled = this.enabled;
					setTimeout(() => this.$emit('committed', this.field.value), 0);
				}
			},
			detectTextChange() {
				setTimeout(() => this.emitChanges(), 0);
			},
			emitChanges() {
				this.$emit('textChanged', this.field.value);
			},
			changeLockState() {
				this.field.enabled = !this.field.enabled;
			},
		}
	});
</script>
