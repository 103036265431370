<template>
	<ion-page>
		<spot-header :title="pageTitle + ' ' + (!tablesData || !tablesData.currentProject ? '' : tablesData.currentProject.name)" :canGoHome="false" :canGoBack="true" @pressedGoHome="$router.push('/home')" @pressedGoBack="exitPackages"></spot-header>

		<ion-content :fullscreen="true"> <!--v-if="isActive"-->
			<ion-progress-bar type="indeterminate" v-if="!isActive"></ion-progress-bar>
			<div id="actionArea" v-else>
				<web-socket-reader crReplacedChar="|" @readedBarcode="onReadedBarcode" @readedTag="onReadedTag" @readerError="onReaderError"></web-socket-reader>
				<spot-button
					:ref="fields[field.shipping].name"
					:text="fields[field.shipping].text"
					:enabled="itemsSelected.length>0"
					:size="fields[field.shipping].size"
					:fill="fields[field.shipping].fill"
					:icon="fields[field.shipping].icon"
					:expand="fields[field.shipping].expand"
					:color="fields[field.shipping].color"
					@clicked="markPackagesAsShipped">
				</spot-button>
				<ion-item>
					<spot-input
						:ref="fields[field.search].name"
						:label="fields[field.search].text"
						:allignLabel="fields[field.search].allignLabel"
						:required="fields[field.search].required"
						:placeholder="fields[field.search].placeholder"
						:enabled="true"
						:value="fields[field.search].value"
						:type="fields[field.search].inputType"
						:clearInput="true"
						@textChanged="changedSearch">
					</spot-input>
				</ion-item>
				<ion-progress-bar type="indeterminate" v-if="!tablesData.packages"></ion-progress-bar> <!-- v-if="!tablesData.projectRows" -->
				<EasyDataTable
					v-model:items-selected="itemsSelected"
					:headers="headers"
					:items="tablesData.packages"
					:sort-by="sortBy"
					:sort-type="sortType"
					:search-field="searchFields"
					:search-value="fields[field.search].value"
					:empty-message="noItemsText"
					alternating
					multi-sort> <!-- :items="tablesData.projectRows" show-index -->
				</EasyDataTable>
			</div>
			<div id="debugArea" style="color:red;" v-if="settings.debug">
				<div>Test Area</div>
				<div v-for="field in fields" :key="field.id">
					{{ field.name }} = {{ field.value }}
				</div>
			</div>
		</ion-content>

		<spot-footer></spot-footer>
	</ion-page>
</template>

<style scoped></style>

<script>
import { /*IonButton, IonCard, IonCardContent, */IonContent, /*IonIcon, */IonItem, /*IonModal, */IonPage, IonProgressBar, /*IonTextarea, IonTitle, IonToolbar*/ } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import SpotButton from '../components/SpotButton.vue';
import SpotInput from '../components/SpotInput.vue';
import SpotFooter from "../components/SpotFooter.vue";
import SpotHeader from "../components/SpotHeader.vue";
import WebSocketReader from '../components/WebSocketReader.vue';
import { /* checkUserSession, */ confirmYesNo /*, insertValue*/, settings, showMsgError, showMsgInfo, showMsgWarning, writeLog } from '../plugins/common.js';
import { getProject, searchPackages, tablesData, updatePackage } from '../plugins/customDatabase.js';

	export default defineComponent({
		name: "ManagePackages",
		props: {
			pageTitle: { type: String, default: 'Carica Colli di' },
			noItemsText: { type: String, default: 'Nessun Collo presente' },
			undoText: { type: String, default: 'Annulla' },
			confirmText: { type: String, default: 'Conferma' },
			unselected: { type: String, default: 'Nessuno' },
			selectedText: { type: String, default: 'Selezionato' },
			deselectText: { type: String, default: 'annulla' },
			selectText: { type: String, default: 'seleziona' },
			messageSearchPackageTitle: { type: String, default: 'Ricerca Collo' },
			messageSearchPackageNotFound: { type: String, default: 'Collo non trovato!' },
			messageTitleCheckTAG: { type: String, default: 'Controllo TAG' },
			warnMultipleTAG: { type: String, default: 'Letto più di un TAG. Avvicinarsi solo al TAG da leggere e riprovare' },
			warnInavlidTAG: { type: String, default: 'TAG letto solo in parte. Riprovare a leggere' },
			confirmDeleteTitle: { type: String, default: 'Conferma Cancellazione' },
			confirmDeleteMessage: { type: String, default: 'Confermi la cancellazione dell\'articolo corrente?' },
			messageTitleReader: { type: String, default: 'Lettura Barcode/TAG' },
			errorReadingReader: { type: String, default: 'Errore nella lettura del barcode/tag' },
			confirmExitTitle: { type: String, default: 'Conferma Uscita' },
			confirmExitMessage: { type: String, default: 'Confermi l\'uscita dalla rilevazione anche se non sono stati inseriti tutti i dati?' },
			messageTitlePrint: { type: String, default: "Stampa Etichetta" },
			messageTitleShipping: { type: String, default: "Carico Camion" },
			successSaveMessage: { type: String, default: "Carico camion avvenuto correttamente. Colli caricati: " },
			//successPrintMessage: { type: String, default: "Stampa etichetta avvenuta correttamente. Etichetta: " },
			successPrintMessage: { type: String, default: "Stampa etichetta avvenuta correttamente. Etichette stampate: " },
			errorPrintMessage: { type: String, default: "Errore durante la stampa dell'etichetta" },
			// errorPrintConfirmMessage: { type: String, default: "Confermi di procedere lo stesso anche se la stampa non è avvenuta (l'etichetta non potrà essere stampata successivamente)?" },
			warningPrinterOffline: { type: String, default: "La stampante non è raggiungibile; l'etichetta non è stata stampata" },
			warningPrintConfirmMessage: { type: String, default: "Confermi di procedere lo stesso anche se non è stata assegnata una stampante (l'etichetta non verrà stampata)?" },
		},
		components: {
			IonPage,
			IonContent,
			IonProgressBar,
			IonItem,
			// IonButton,
			// IonIcon,
			SpotHeader,
			SpotFooter,
			SpotInput,
			SpotButton,
			WebSocketReader,
		},
		data: () => ({
			isActive: null,
			field: {
				shipping: 0,
				search: 1,
			},
			fields: [
				{ id: 0, name: 'shipping', text: 'Carica selezionati', enabled: null, inputType: 'button', size: 'default', fill: 'solid', expand: 'block', icon: 'bagAdd', color: 'primary' },
				{ id: 1, name: 'search', text: 'Cerca: ', allignLabel: true, placeholder: 'Cerca Collo', inputType: 'text', defaultValue: '', value: null, required: false, refField: null },
			],
			searchFields: ['name'],
			headers: [
				{ text: 'Name', value: 'name', sortable: true },
			],
			sortType: ['asc'],
			sortBy: ['name'],
			actionDbCreate: null,
			isIncoming: null,
			isOutgoing: null,
			isPicking: null,
			isRevision: null,
			isLocked: null,
			projectId: null,
			documentId: null,
			printerId: null,
			lastBarcode: null,
			lastTag: null,
			settings,
			// checkUserSession,
			confirmYesNo,
			showMsgError,
			showMsgInfo,
			showMsgWarning,
			writeLog,
			getProject,
			searchPackages,
			updatePackage,
			tablesData,
		}),
		async ionViewWillEnter() {
			this.getUrlParamsAndQuery();
			this.setDefault();
			await this.getAllTablesData();
			this.isActive = true;
		},
		ionViewWillLeave() {
			//this.showMsgWarning("DEBUG","DEBUG");
			this.isActive=false;
		},
		computed: {
		},
		setup() {
			const itemsSelected = ref([]);
			return {
				itemsSelected,
			};
		},
		methods: {
			// Settings & StartUp
			getUrlParamsAndQuery() {
				this.isIncoming = (/true/i).test(this.$route.query.incoming);
				this.isOutgoing = (/true/i).test(this.$route.query.outgoing);
				this.isPicking = (/true/i).test(this.$route.query.picking);
				this.isRevision = (/true/i).test(this.$route.query.revision);
				this.isLocked = (/true/i).test(this.$route.query.locked);
				this.projectId = this.$route.params.id;
			},
			setDefault() {
				this.fields.forEach(element => element.value = element.defaultValue);
				this.actionDbCreate = null;
				this.lastBarcode = null;
				this.lastTag = null;
			},
			getPlaceHolder(element) {
				return ((this.settings.showFieldsPlaceholder) ? (element.required ? element.placeholder : this.unselected ) : '')
			},
			// Database
			async getAllTablesData() {
				await this.getProject(this.projectId);
				// this.tablesData.projectRows = [];
				// this.tablesData.packages = [];
				await this.searchPackages(this.projectId, true, false);
			},
			// Actions
			async onReadedBarcode(barcode,type) {
				this.lastBarcode = barcode;
				this.writeLog(barcode, type);
				await this.committedBarcode();
			},
			async onReadedTag(tag,type) {
				this.lastTag = tag;
				this.writeLog(tag, type);
				await this.committedTag();
			},
			onReaderError(data,error) {
				this.showMsgError(data, error);
				this.showMsgWarning(this.messageTitleReader, this.errorReadingReader);
			},
			changedSearch(value) {
				this.fields[this.field.search].value = value;
			},
			async markPackagesAsShipped() {
				for(const element of this.itemsSelected) {
					await this.updatePackage(element.id, this.prepareUpdateRecord());
				}
				this.showMsgInfo(
					this.messageTitleShipping,
					this.successSaveMessage + "&nbsp" + this.itemsSelected.length);
				this.exitPackages();
			},
			prepareUpdateRecord() {
				let myRecord = {
					package: {
						shipped: true,
					}
				};
				return myRecord;
			},
			changedBarcode(value) {
				this.lastBarcode = value;
			},
			async committedBarcode() {
				let foundElement = null;
				this.tablesData.packages.some(element => {
					if (element.name == this.lastBarcode) {
						foundElement = element;
						return true;
					}
				});
				if (!foundElement) this.showMsgWarning(this.messageSearchPackageTitle, this.messageSearchPackageNotFound);
				else if (!this.itemsSelected.includes(foundElement)) this.itemsSelected.push(foundElement);
			},
			changedTag(value) {
				this.lastTag = value;
			},
			async committedTag() {
				let tagElements = this.lastTag.split('|');
				let foundElement = null;

				switch (tagElements.length) {
					case 0:
						this.lastTag = null;
						this.showMsgWarning(this.messageTitleCheckTAG,this.warnInavlidTAG);
						break;
					case 1:
						this.tablesData.picks.some(pick => {
							if (pick.item.code == this.lastTag) {
								foundElement = pick;
								return true;
							}
						});
						if (!foundElement) this.showMsgWarning(this.messageSearchPackageTitle, this.messageSearchPackageNotFound);
						else this.editExistingPick(foundElement);
						break;
					default:
						this.lastTag = null;
						this.showMsgWarning(this.messageTitleCheckTAG,this.warnMultipleTAG);
						break;
				}
			},
			exitPackages() {
				this.$router.push("/package" + 
					"?incoming="+this.isIncoming+
					"&outgoing="+this.isOutgoing+
					"&picking="+this.isPicking+
					"&revision="+this.isRevision+
					"&locked="+this.isLocked
				);
			}
		}
	});
</script>
