<template>
	<ion-page>
		<spot-header :title="feName" :canGoHome="true" :canGoBack="false" @pressedGoHome="$router.push('/home')" @pressedGoBack="$router.push('/movement')"></spot-header>

		<ion-content :fullscreen="true" v-if="isActive">
		</ion-content>

		<spot-footer></spot-footer>
	</ion-page>
</template>

<style scoped></style>

<script>
import { feName } from "@/plugins/variables.js";
import { IonContent, IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';
import SpotFooter from "../components/SpotFooter.vue";
import SpotHeader from "../components/SpotHeader.vue";

	export default defineComponent({
		name: "MoveItems",
		components: {
			IonPage,
			IonContent,
			SpotHeader,
			SpotFooter
		},
		data: () => ({
			isActive: null,
			feName,
		}),
		ionViewWillEnter() {
			this.setDefault();
			this.getAllTablesData();
			this.isActive=true;
		},
		ionViewWillLeave() {
			this.isActive=false;
		},
		methods: {
			// Settings & StartUp
			setDefault() {
				// Do Nothing
			},
			getAllTablesData() {
				// Do Nothing
			},
			// Database
			// Actions
		}
	});
</script>