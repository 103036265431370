import { checkUserSession, settings } from "../plugins/common.js";
import { deleteData, getData, readData, searchQueryData, updateData, writeData } from "./database.js";

export var tablesData = {
	currentLocation: null,
	currentDocument: null,
	currentReferenceItem: null,
	currentMeasureUnit: null,
	currentItem: null,
	currentProjectTypology: null,
	currentProject: null,
	currentProjectRow: null,
	currentPick: null,
	currentTrack: null,
	currentPackage: null,
	currentPrinter: null,
	currentPrintTemplate: null,
	printerActionResult: null,
	locations: [],
	documents: [],
	referenceItems: [],
	measureUnits: [],
	items: [],
	projectTypologies: [],
	projects: [],
	projectRows: [],
	picks: [],
	tracks:[],
	packages: [],
	printers: [],
	printTemplates: [],
}
export var tablesToBeRefreshed = {
	locations: false,
	documents: false,
	referenceItems: false,
	measureUnits: false,
	items: false,
	projectTypologies: false,
	projects: false,
	projectRows: false,
	picks: false,
	tracks: false,
	packages: false,
	printers: false,
	printTemplates: false,
}

export async function hasPrinter() {
	await getData("printers")
	.then(data => {
		(data.length > 0) ? settings.printerManagement = true : settings.printerManagement = false;
		this.tablesData.printers = data;
		return true;
	})
	.catch(error => {
		checkUserSession(error, "warning");
		return false;
	});
}
export async function getLocations() { 
	await getData("locations")
	.then(data => { tablesData.locations = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function getDocuments() { 
	await getData("documents")
	.then(data => { tablesData.documents = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function getReferenceItems() { 
	await getData("documents")
	.then(data => { tablesData.referenceItem = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function getMeasureUnits() {
	await getData("measure_units")
	.then(data => { tablesData.measureUnits = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function getItems() {
	await getData("items")
	.then(data => { tablesData.items = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function getProjectTypologies() {
	await getData("project_typologies")
	.then(data => { tablesData.projectTypologies = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function getProjects() {
	await getData("projects")
	.then(data => { tablesData.projects = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function getProjectRows() {
	await getData("project_rows")
	.then(data => { tablesData.projectRows = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function getPicks() {
	await getData("picks")
	.then(data => { tablesData.picks = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function getTracks() {
	await getData("tracks")
	.then(data => { tablesData.tracks = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function getPackages() {
	await getData("packages")
	.then(data => { tablesData.packages = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function getPrinters() {
	await getData("printers")
	.then(data => { tablesData.printers = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function getPrintTemplates() {
	await getData("print_templates")
	.then(data => { tablesData.printTemplates = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function searchDocuments(project_id = null) {
	let mySearch = {
		params: {
			// 'q[project_id_eq]': project_id,
			'q[parent_id_null]': true,
		}
	};
	!project_id ? mySearch.params['q[project_id_null]'] = true  : mySearch.params['q[project_id_eq]'] = project_id;
	await searchQueryData("documents", mySearch)
	.then(data => { tablesData.documents = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function searchReferenceItems(project_id = null, parent_id) {
	let mySearch = {
		params: {
			// 'q[project_id_eq]': project_id,
			'q[parent_id_eq]': parent_id,
		}
	};
	!project_id ? mySearch.params['q[project_id_null]'] = true  : mySearch.params['q[project_id_eq]'] = project_id;
	await searchQueryData("documents", mySearch)
	.then(data => { tablesData.referenceItems = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function searchProjects(project_typology_id, closed = null, partial = null, delivered = null) {
	let mySearch = {
		params: {
			'q[project_typology_id_eq]': project_typology_id,
		}
	};
	if (closed) mySearch.params['q[closed_true]'] = closed
	if (partial) mySearch.params['q[partial_true]'] = partial
	if (delivered) mySearch.params['q[delivered_true]'] = delivered
	await searchQueryData("projects", mySearch)
	.then(data => { tablesData.projects = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function searchProjectRows(project_id) {
	let mySearch = {
		params: {
			'q[project_id_eq]': project_id,
		}
	};
	await searchQueryData("project_rows", mySearch)
	.then(data => { tablesData.projectRows = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function searchPicks(project_id, document_id = null) {
	let mySearch = {
		params: {
			'q[project_id_eq]': project_id,
		}
	};
	!document_id ? mySearch.params['q[document_id_null]'] = true  : mySearch.params['q[document_id_eq]'] = document_id;
	await searchQueryData("picks", mySearch)
	.then(data => { tablesData.picks = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function searchTracks(project_row_id) {
	let mySearch = {
		params: {
			'q[project_row_id_eq]': project_row_id,
		}
	};
	await searchQueryData("tracks", mySearch)
	.then(data => { tablesData.tracks = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function searchPackages(project_id = null, closed = false, shipped = false) {
	let mySearch = {
		params: {
			'q[closed_eq]': closed,
			'q[shipped_eq]': shipped,
		}
	};
	!project_id ? mySearch.params['q[project_id_null]'] = true  : mySearch.params['q[project_id_eq]'] = project_id;
	await searchQueryData("packages", mySearch)
	.then(data => { tablesData.packages = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function createItem(item_data) {
	await writeData("items", item_data)
	.then(async (data) => {	await getItems();	tablesData.currentItem = data;	return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function createProject(project_data) {
	await writeData("projects", project_data)
	.then(async (data) => {	await getProjects();	tablesData.currentProject = data;	return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function createProjectRow(project_row_data) {
	await writeData("project_rows", project_row_data)
	.then(async (data) => {	await getProjectRows();	tablesData.currentProjectRow = data;	return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function createPick(pick_data) {
	await writeData("picks", pick_data)
	.then(async (data) => {	await getPicks();	tablesData.currentPick = data;	return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function createTrack(track_data) {
	await writeData("tracks", track_data)
	.then(async (data) => {	await getTracks();	tablesData.currentTrack = data;	return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function createPackage(package_data) {
	await writeData("packages", package_data)
	.then(async (data) => {	await getPackages();	tablesData.currentPackage = data;	return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function getLocation(location_id) {
	await readData("locations", location_id)
	.then(data => { tablesData.currentLocation = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function getDocument(document_id) {
	await readData("documents", document_id)
	.then(data => { tablesData.currentDocument = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function getReferenceItem(document_id) {
	await readData("documents", document_id)
	.then(data => { tablesData.currentReferenceItem = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function getItem(item_id) {
	await readData("items", item_id)
	.then(data => { tablesData.currentItem = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function getProject(project_id) {
	await readData("projects", project_id)
	.then(data => { tablesData.currentProject = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function getProjectRow(project_row_id) {
	await readData("project_rows", project_row_id)
	.then(data => { tablesData.currentProjectRow = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function getPick(pick_id) {
	await readData("picks", pick_id)
	.then(data => { tablesData.currentPick = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function getPackage(package_id) {
	await readData("packages", package_id)
	.then(data => { tablesData.currentPackage = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function getPrinter(printer_id) {
	await readData("printers", printer_id)
	.then(data => { tablesData.currentPrinter = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function getPrintTemplate(print_template_id) {
	await readData("print_templates", print_template_id)
	.then(data => { tablesData.currentPrintTemplate = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function updateDocument(document_id, document_data) {
	await updateData("documents", document_id, document_data)
	.then(async (data) => {	await getDocuments();	tablesData.currentDocument = data;	return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function updateItem(item_id, item_data) {
	await updateData("items", item_id, item_data)
	.then(async (data) => {	await getItems();	tablesData.currentItem = data;	return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function updateProject(project_id, project_data) {
	await updateData("projects", project_id, project_data)
	.then(async (data) => {	await getProjects();	tablesData.currentProject = data;	return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function updateProjectRow(project_row_id, project_row_data) {
	await updateData("project_rows", project_row_id, project_row_data)
	.then(async (data) => {	await getProjectRows();	tablesData.currentProjectRow = data;	return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function updatePick(pick_id, pick_data) {
	await updateData("picks", pick_id, pick_data)
	.then(async (data) => {	await getPicks();	tablesData.currentPick = data;	return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function updatePackage(package_id, package_data) {
	await updateData("packages", package_id, package_data)
	.then(async (data) => {	await getPackages();	tablesData.currentPackage = data;	return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function updatePrinter(printer_id, printer_data) {
	await updateData("printers", printer_id, printer_data)
	.then(async (data) => {	await getPrinters();	tablesData.currentPrinter = data;	return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function searchItem(code) {
	let mySearch = {
		params: {
			'q[code_eq]': code,
		}
	};
	await searchQueryData("items", mySearch)
	.then(data => { tablesData.currentItem = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function searchProjectTypology(title) {
	let mySearch = {
		params: {
			'q[title_eq]': title,
		}
	};
	await searchQueryData("project_typologies", mySearch)
	.then(data => { tablesData.currentProjectTypology = data[0]; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function searchProject(name) {
	let mySearch = {
		params: {
			'q[name_eq]': name,
		}
	};
	await searchQueryData("projects", mySearch)
	.then(data => { tablesData.currentProject = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function searchPick(project_row_id) {
	let mySearch = {
		params: {
			'q[project_row_id_eq]': project_row_id,
		}
	};
	await searchQueryData("picks", mySearch)
	.then(data => { tablesData.currentPick = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function searchPrinter(isDefaulPrinter) {
	let mySearch = {
		params: {
			'q[default_eq]': isDefaulPrinter,
		}
	};
	await searchQueryData("printers", mySearch)
	.then(data => { tablesData.currentPrinter = data; return true;})
	.catch(error => { checkUserSession(error, "warning"); return false; });
}
export async function deleteItem(item_id) {
	await deleteData("items", item_id)
	.then(async () => {	await getItems();	tablesData.currentItem = null; return true; })
	.catch(error => { checkUserSession(error, "warning"); return false; });	
}
export async function deleteProject(project_id) {
	await deleteData("projects", project_id)
	.then(async () => {	await getProjects();	tablesData.currentProject = null; return true; })
	.catch(error => { checkUserSession(error, "warning"); return false; });	
}
export async function deleteProjectRow(project_row_id) {
	await deleteData("project_rows", project_row_id)
	.then(async () => {	await getProjectRows();	tablesData.currentProjectRow = null; return true; })
	.catch(error => { checkUserSession(error, "warning"); return false; });	
}
export async function deletePick(pick_id) {
	await deleteData("picks", pick_id)
	.then(async () => {	await getPicks();	tablesData.currentPick = null; return true; })
	.catch(error => { checkUserSession(error, "warning"); return false; });	
}
export async function deletePackage(package_id) {
	await deleteData("packages", package_id)
	.then(async () => {	await getPicks();	tablesData.currentPackage = null; return true; })
	.catch(error => { checkUserSession(error, "warning"); return false; });	
}
export async function executePrinterAction(destinationUrl) {
	await getData(destinationUrl)
	.then(data => {
		tablesData.printerActionResult = data;
		tablesData.printerIsOnErrorState = false;
		return true;
	})
	/*
	.catch(error => {
		checkUserSession(error, "warning");
		return false;
	});
	*/
}
export async function getAllTablesData() {
	if (tablesToBeRefreshed.locations) await getLocations();
	if (tablesToBeRefreshed.documents) await getDocuments();
	if (tablesToBeRefreshed.referenceItems) await getReferenceItems();
	if (tablesToBeRefreshed.measureUnits) await getMeasureUnits();
	if (tablesToBeRefreshed.items) await getItems();
	if (tablesToBeRefreshed.projectTypologies) await getProjectTypologies();
	if (tablesToBeRefreshed.projects) await getProjects();
	if (tablesToBeRefreshed.projectRows) await getProjectRows();
	if (tablesToBeRefreshed.picks) await getPicks();
	if (tablesToBeRefreshed.tracks) await getTracks();
	if (tablesToBeRefreshed.packages) await getPackages();
	if (tablesToBeRefreshed.printers) getPrinters();
	if (tablesToBeRefreshed.printTemplates) await getPrintTemplates();
}
