<template>
 <ion-page>
		<spot-header :title="pageTitle + ' ' + (!tablesData || !tablesData.currentPackage ? '' : tablesData.currentPackage.name)" :canGoHome="false" :canGoBack="true" @pressedGoHome="$router.push('/home')" @pressedGoBack="exitProject"></spot-header>

		<ion-content :fullscreen="true"> <!--v-if="isActive"-->
			<ion-progress-bar type="indeterminate" v-if="!isActive"></ion-progress-bar>
			<div id="actionArea" v-else>
				<web-socket-reader crReplacedChar="|" @readedBarcode="onReadedBarcode" @readedTag="onReadedTag" @readerError="onReaderError"></web-socket-reader>
				<ion-item>
					<spot-input
						:ref="fields[field.search].name"
						:label="fields[field.search].text"
						:allignLabel="fields[field.search].allignLabel"
						:required="fields[field.search].required"
						:placeholder="fields[field.search].placeholder"
						:enabled="true"
						:value="fields[field.search].value"
						:type="fields[field.search].inputType"
						:clearInput="true"
						@textChanged="changedSearch">
					</spot-input>
					<!--
					<ion-button size="default" slot="end" disabled="true" @click="createNewPick">
						<ion-icon :icon="duplicate" slot="start" size="default"></ion-icon>
						{{ newItemText }}
					</ion-button>
					-->
				</ion-item>
				<ion-progress-bar type="indeterminate" v-if="!tablesData.picks"></ion-progress-bar> <!-- v-if="!tablesData.projectRows" -->
				<EasyDataTable
					:headers="headers"
					:items="tablesData.picks"
					:sort-by="sortBy"
					:sort-type="sortType"
					:search-field="searchFields"
					:search-value="fields[field.search].value"
					:empty-message="noItemsText"
					:body-row-class-name="bodyRowClassNameFunction"
					@click-row="createNewProjectRow"
					multi-sort> <!-- :items="tablesData.projectRows" show-index alternating -->
					<!--
					<template #item-actions="pick">
						<ion-button disabled=false fill="outline" shape="round" size="small" slot="icon-only" @click="showExistingPick(pick)">
							<ion-icon :icon="reader" slot="end" size="small"></ion-icon>
						</ion-button>
						<ion-button :color="pick.quantity_detected == pick.quantity ? 'success' : (pick.quantity_detected == 0 ? 'danger' : (pick.quantity_detected < pick.quantity ? 'warning' : 'tertiary'))" disabled=false fill="outline" shape="round" size="small" slot="icon-only" @click="editExistingPick(pick)">
							<ion-icon :icon="brush" slot="end" size="small"></ion-icon>
						</ion-button>
						<ion-button disabled=true fill="outline" shape="round" size="small" slot="icon-only" @click="deleteExistingPick(pick)">
							<ion-icon :icon="trash" slot="end" size="small"></ion-icon>
						</ion-button>
					</template>
					<template #item-actions="projectRow">
						<ion-button disabled=false fill="outline" shape="round" size="small" slot="icon-only" @click="showExistingProjectRow(projectRow)">
							<ion-icon :icon="reader" slot="end" size="small"></ion-icon>
						</ion-button>
						<ion-button :color="pick.quantity_detected == pick.quantity ? 'success' : (pick.quantity_detected == 0 ? 'danger' : (pick.quantity_detected < pick.quantity ? 'warning' : 'tertiary'))" disabled=false fill="outline" shape="round" size="small" slot="icon-only" @click="editExistingProjectRow(projectRow)">
							<ion-icon :icon="brush" slot="end" size="small"></ion-icon>
						</ion-button>
						<ion-button disabled=true fill="outline" shape="round" size="small" slot="icon-only" @click="deleteExistingProjectRow(projectRow)">
							<ion-icon :icon="trash" slot="end" size="small"></ion-icon>
						</ion-button>
					</template>
					-->
					<template #expand="item">
						{{item.item.description}}
					</template>
				</EasyDataTable>
				<ion-modal ref="modal" backdrop-dismiss="false" :is-open="isOpenModal" @didDismiss="modalDismissed">
					<ion-content>
						<ion-toolbar>
							<ion-title> {{ modalTitle }}</ion-title>
						</ion-toolbar>
						<ion-card disabled="false">
							<ion-card-content class="flex-column-left-container">
								<div lines="none" v-for="field in itemContents" :key="field.id" class="no-pad">
									<spot-input
										v-if="(field.inputType == 'text' || field.inputType == 'number')"
										:ref="field.name"
										:label="field.text"
										:required="field.required"
										:placeholder="getPlaceHolder(field)"
										:enabled="field.enabled"
										:validationState="field.validationState"
										:furtherInfo="field.furtherInfo"
										:value="field.value"
										:type="field.inputType"
										@textChanged="changedTextField(field.id, $event)">
									</spot-input>
									<ion-textarea
										v-if="field.inputType == 'area'"
										:ref="field.name"
										:placeholder="getPlaceHolder(field)"
										:required="field.required"
										:disabled="!field.enabled"
										:auto-grow="true"
										:value="field.value">
									</ion-textarea>
									<spot-checkbox
										v-if="field.inputType == 'check'"
										:ref="field.name"
										:label="field.text"
										:required="field.required"
										:enabled="modalReadOnly == true ? false : (field.id == 5 ? true : false)"
										:validationState="field.validationState"
										:value="field.value"
										@checkboxChanged="changedCheckboxField(field.id, $event)"
										@focus="$event.target.select()">
									</spot-checkbox>
									<spot-select
										v-if="field.inputType == 'select'"
										:ref="field.name"
										:label="field.text"
										:required="field.required"
										:placeholder="getPlaceHolder(field)"
										:enabled="field.enabled"
										:validationState="field.validationState"
										:value="field.value"
										:options="tablesData.packages"
										:optionField="field.refField"
										:canAddItem="field.canAddItem"
										:canRefreshList="field.canRefresh"
										:canUnlock="field.canUnlock"
										:selectedLabel="selectedText"
										:deselectLabel="deselectText"
										:selectLabel="selectText"
										@selected="assignedSelectField(field.id, $event)"
										@unselected="resetSelectField(field.id)"
										@addItem="addPackageAndPrintLabel">
									</spot-select>
								</div>
							</ion-card-content>
						</ion-card>
						<div class="flex-row-center-container">
							<spot-button
								fill="outline"
								size="large"
								color="primary"
								:enabled="true"
								:expand="null"
								:text="undoText"
								@clicked="closeModal(false)">
							</spot-button>
							<spot-button
								fill="outline"
								size="large"
								color="primary"
								:enabled="!isEmptyRequiredFields && modalReadOnly == false"
								:expand="null"
								:text="confirmText"
								@clicked="closeModal(true)">
							</spot-button>
						</div>
					</ion-content>
				</ion-modal>
			</div>
			<div id="debugArea" style="color:red;" v-if="settings.debug">
				<div>Test Area</div>
				<div v-for="field in fields" :key="field.id">
					{{ field.name }} = {{ field.value }}
				</div>
			</div>
		</ion-content>

		<spot-footer></spot-footer>
 </ion-page>
</template>

<style scoped>
	ion-modal {
		--height: 80%;
		--width: 90%;
		--border-radius: 16px;
		--box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
	}
	ion-modal::part(backdrop) {
		background: var(--ion-color-medium);
		opacity: 1;
	}
	ion-modal ion-toolbar {
		--background: var(--ion-color-primary);
		--color: white;
	}
	ion-textarea {
		font-size: 12px;
	}
	.flex-column-left-container {
		margin-top: 0.2em;
		margin-bottom: 0.2em;
		text-align: left;
		display: flex;
		flex-direction: column;
		/*justify-content: left;*/
		/*align-items: left;*/
		flex-wrap: wrap;
	}
	.flex-row-center-container {
		margin-top: 0.2em;
		text-align: center;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
	.no-pad {
		margin-top: 0px;
		margin-bottom: 0px;
		--padding-top: 0px;
		--padding-bottom: 0px;
		--inner-padding-top: 0px;
		--inner-padding-bottom: 0px;
		--min-height: 0px
	}
	*:deep(ion-checkbox) {
		margin-top: 0px;
		margin-bottom: 0px;
	}
</style>
<style>
	.empty-row  {
		--easy-table-body-row-background-color: var(--ion-color-danger); /*#f56c6c;*/
	}
	.partial-row  {
		--easy-table-body-row-background-color: var(--ion-color-warning); /*#ddc229;*/
	}
	.completed-row  {
		--easy-table-body-row-background-color: var(--ion-color-success); /*#67c23a;*/
	}
	.exceeded-row  {
		--easy-table-body-row-background-color: var(--ion-color-tertiary);/*#0d9de0;*/
	}
</style>

<script>
import { /*IonButton, */IonCard, IonCardContent, IonContent, /*IonIcon, */IonItem, IonModal, IonPage, IonProgressBar, IonTextarea, IonTitle, IonToolbar } from '@ionic/vue';
import { brush, duplicate, trash } from 'ionicons/icons';
import { defineComponent } from 'vue';
import SpotButton from '../components/SpotButton.vue';
import SpotCheckbox from '../components/SpotCheckbox.vue';
import SpotSelect from '../components/SpotSelect.vue';
import SpotInput from '../components/SpotInput.vue';
import SpotFooter from "../components/SpotFooter.vue";
import SpotHeader from "../components/SpotHeader.vue";
import WebSocketReader from '../components/WebSocketReader.vue';
import { checkUserSession, confirmYesNo /*, insertValue*/, settings, showMsgError, showMsgInfo, showMsgWarning, writeLog } from '../plugins/common.js';
import { getPrinter, createPackage, createProjectRow, createPick, deleteProjectRow, deletePick, executePrinterAction, getPick, getProject, searchPackages, searchPicks, searchPrinter, searchProjectRows, tablesData, updateDocument, updateProjectRow, updatePick, updateProject } from '../plugins/customDatabase.js';

	export default defineComponent({
		name: "ManageOrder",
		props: {
			pageTitle: { type: String, default: 'Gestisci Collo' },
			noItemsText: { type: String, default: 'Nessun Articolo presente' },
			newItemText: { type: String, default: 'Nuovo Articolo' },
			editItemText: { type: String, default: 'Modifica Articolo'}, //'Modifica Articolo: '
			showItemText: { type: String, default: 'Visualizza Articolo'}, //'Visualizza Articolo: '
			undoText: { type: String, default: 'Annulla' },
			confirmText: { type: String, default: 'Conferma' },
			unselected: { type: String, default: 'Nessuno' },
			selectedText: { type: String, default: 'Selezionato' },
			deselectText: { type: String, default: 'annulla' },
			selectText: { type: String, default: 'seleziona' },
			messageSearchTitle: { type: String, default: 'Ricerca Articolo' },
			messageSearchNotFound: { type: String, default: 'Articolo non trovato!' },
			messageTitleCheckTAG: { type: String, default: 'Controllo TAG' },
			warnMultipleTAG: { type: String, default: 'Letto più di un TAG. Avvicinarsi solo al TAG da leggere e riprovare' },
			warnInavlidTAG: { type: String, default: 'TAG letto solo in parte. Riprovare a leggere' },
			confirmDeleteTitle: { type: String, default: 'Conferma Cancellazione' },
			confirmDeleteMessage: { type: String, default: 'Confermi la cancellazione dell\'articolo corrente?' },
			messageTitleReader: { type: String, default: 'Lettura Barcode/TAG' },
			errorReadingReader: { type: String, default: 'Errore nella lettura del barcode/tag' },
			successSaveMessage: { type: String, default: "Record salvato correttamente" },
			confirmExitTitle: { type: String, default: 'Conferma Uscita' },
			confirmExitMessage: { type: String, default: 'Confermi l\'uscita dalla rilevazione anche se non sono stati inseriti tutti i dati?' },
			messageTitleInsert: { type: String, default: "Inserimento Nuovo Collo" },
			messageTitlePrint: { type: String, default: "Stampa Etichetta" },
			//successPrintMessage: { type: String, default: "Stampa etichetta avvenuta correttamente. Etichetta: " },
			successPrintMessage: { type: String, default: "Stampa etichetta avvenuta correttamente. Etichette stampate: " },
			errorPrintMessage: { type: String, default: "Errore durante la stampa dell'etichetta" },
			// errorPrintConfirmMessage: { type: String, default: "Confermi di procedere lo stesso anche se la stampa non è avvenuta (l'etichetta non potrà essere stampata successivamente)?" },
			warningPrinterOffline: { type: String, default: "La stampante non è raggiungibile; l'etichetta non è stata stampata" },
			warningPrintConfirmMessage: { type: String, default: "Confermi di procedere lo stesso anche se non è stata assegnata una stampante (l'etichetta non verrà stampata)?" },
		},
		components: {
			IonPage,
			IonContent,
			IonProgressBar,
			IonItem,
			// IonButton,
			// IonIcon,
			IonModal,
			IonToolbar,
			IonTitle,
			IonCard,
			IonCardContent,
			IonTextarea,
			SpotHeader,
			SpotFooter,
			SpotInput,
			SpotCheckbox,
			SpotSelect,
			SpotButton,
			WebSocketReader,
		},
		data: () => ({
			isActive: null,
			field: {
				search: 0,
			},
			fields: [
				{ id: 0, name: 'search', text: 'Cerca: ', allignLabel: true, placeholder: 'Cerca Articolo', inputType: 'text', defaultValue: '', value: null, required: false, refField: null },
			],
			searchFields: ['item.code','item.description'],
			headers: [
				// { text: 'Ubicazione', value: 'location.name', sortable: true },
				{ text: 'Articolo', value: 'item.code', sortable: true },
				{ text: 'UM', value: 'item.measure_unit.name', sortable: false },
				{ text: 'Previsti', value: 'quantity', sortable: false },
				{ text: 'Rilevati', value: 'quantity_detected', sortable: false },
				// { text: 'Azioni', value: 'actions', sortable: false }
			],
			sortType: ['asc', 'asc'],
			sortBy: ['location.name','item.code'],
			itemContent: {
				code: 0,
				description: 1,
				expectedQuantity: 2,
				quantity: 3,
				package: 4,
			},
			itemContents: [
				{ id: 0, name: 'code', text: 'Articolo', enabled: false, placeholder: 'Inserisci Codice Articolo', inputType: 'text', defaultValue: null, value: null, required: true, furtherInfo: null, validationState: null, canAddItem: false, canRefresh: false, canUnlock: false },
				{ id: 1, name: 'description', text: 'Descrizione', enabled: false, placeholder: 'Inserisci Descrizione', inputType: 'area', defaultValue: null, value: null, required: true, furtherInfo: null, validationState: null, canAddItem: false, canRefresh: false, canUnlock: false },
				{ id: 2, name: 'expectedQuantity', enabled: false, text: 'Quantità Prevista', placeholder: 'Inserisci Quantità Prevista', inputType: 'number', defaultValue: null, value: null, required: true, furtherInfo: null, validationState: null, canAddItem: false, canRefresh: false, canUnlock: false },
				{ id: 3, name: 'quantity', text: 'Quantità', enabled: true, placeholder: 'Inserisci Quantità', inputType: 'number',  defaultValue: null, value: null, required: true, furtherInfo: null, validationState: null, canAddItem: false, canRefresh: false, canUnlock: false },
				{ id: 4, name: 'package', text: 'Cod. Pacco', enabled: true, placeholder: 'Inserisci Cod. Pacco', inputType: 'select', defaultValue: null, value: null, required: true, refField: 'name', validationState: null, canAddItem: true, canRefresh: false, canUnlock: false  },
			],
			isOpenModal: false,
			modalTitle: null,
			modalReadOnly: null,
			actionDbCreate: null,
			isIncoming: null,
			isOutgoing: null,
			isPicking: null,
			isRevision: null,
			isLocked: null,
			projectId: null,
			documentId: null,
			printerId: null,
			lastBarcode: null,
			lastTag: null,
			duplicate,
			trash,
			brush,
			settings,
			checkUserSession,
			confirmYesNo,
			showMsgError,
			showMsgInfo,
			showMsgWarning,
			writeLog,
			getPick,
			getPrinter,
			getProject,
			searchPicks,
			searchProjectRows,
			searchPackages,
			createPick,
			searchPrinter,
			createProjectRow,
			createPackage,
			updatePick,
			updateProjectRow,
			deletePick,
			deleteProjectRow,
			updateDocument,
			updateProject,
			executePrinterAction,
			tablesData,
		}),
		async ionViewWillEnter() {
			this.getUrlParamsAndQuery();
			this.setDefault();
			await this.getAllTablesData();
			this.isActive = true;
		},
		ionViewWillLeave() {
			//this.showMsgWarning("DEBUG","DEBUG");
			this.isOpenModal = false;
			this.isActive=false;
		},
		computed: {
			isEmptyRequiredFields() {
				let ret = false;
				this.itemContents.forEach(element => {if (element.required && !element.value && element.id > 0) ret=true});
				return ret;
			},
			isOrderClean() {
				let ret = true;
				this.tablesData.picks.forEach(element => {if (element.quantity_detected > 0) ret=false});
				return ret;
			},
			isOrderFinished() {
				let ret = true;
				this.tablesData.picks.forEach(element => {if (element.quantity != element.quantity_detected) ret=false});
				return ret;
			},
			canPrint() {
				return (this.settings.printerManagement && (this.printerId != null))
			}
		},
		setup() {
			const bodyRowClassNameFunction = (item) => {
				if (parseFloat(item.quantity_detected) == parseFloat(item.quantity)) return 'completed-row'
				else if(parseFloat(item.quantity_detected) == parseFloat(0)) return 'empty-row'
				else if(parseFloat(item.quantity_detected) < parseFloat(item.quantity)) return 'partial-row'
				else return 'exceeded-row';
			}
			return {
				bodyRowClassNameFunction,
			};
		},
		methods: {
			// Settings & StartUp
			getUrlParamsAndQuery() {
				this.isIncoming = (/true/i).test(this.$route.query.incoming);
				this.isOutgoing = (/true/i).test(this.$route.query.outgoing);
				this.isPicking = (/true/i).test(this.$route.query.picking);
				this.isRevision = (/true/i).test(this.$route.query.revision);
				this.isLocked = (/true/i).test(this.$route.query.locked);
				this.documentId = parseInt(this.$route.query.document);
				this.projectId = this.$route.params.id;
			},
			setDefault() {
				this.fields.forEach(element => element.value = element.defaultValue);
				this.isOpenModal = false;
				this.modalTitle = null;
				this.modalReadOnly = null;
				this.actionDbCreate = null;
				this.lastBarcode = null;
				this.lastTag = null;
			},
			getPlaceHolder(element) {
				return ((this.settings.showFieldsPlaceholder) ? (element.required ? element.placeholder : this.unselected ) : '')
			},
			// Database
			async getAllTablesData() {
				await this.getProject(this.projectId);
				// this.tablesData.projectRows = [];
				this.tablesData.picks = [];
				// await this.searchProjectRows(this.projectId);
				await this.searchPicks(this.projectId, this.documentId);
				this.tablesData.packages = [];
				await this.searchPackages(this.projectId, false, false);
				if (this.settings.printerManagement == true) {
					this.tablesData.printers = [];
					this.tablesData.currentPrinter = null;
					await this.searchPrinter(true);
					if (!this.tablesData.currentPrinter || this.tablesData.currentPrinter.length == 0) {
						this.printerId = null;
						this.confirmYesNo(this.messageTitlePrint, this.warningPrintConfirmMessage)
						.then((data) => {
							if (data == false) {
								if (this.isIncoming == true) this.$router.push("/selection" + "?incoming=true&outgoing=false&picking=false");
								if (this.isOutgoing == true) this.$router.push("/selection" + "?incoming=false&outgoing=true&picking=false");
								if (this.isPicking == true) this.$router.push("/selection" + "?incoming=false&outgoing=false&picking=true");
							}
						})
					}
					else {
						this.printerId = this.tablesData.currentPrinter[0].id;
					}
				}
			},
			async deleteExistingPick(pick) {
				let canDelete = false;
				await this.confirmYesNo(this.confirmDeleteTitle, this.confirmDeleteMessage)
					.then((data) => { canDelete = data })
				if (canDelete == true) {
					await this.deletePick(pick.id)
				}
			},
			async deleteExistingProjectRow(projectRow) {
				let canDelete = false;
				await this.confirmYesNo(this.confirmDeleteTitle, this.confirmDeleteMessage)
					.then((data) => { canDelete = data })
				if (canDelete == true) {
					await this.deleteProjectRow(projectRow.id)
				}
			},
			// Actions
			async onReadedBarcode(barcode,type) {
				this.lastBarcode = barcode;
				this.writeLog(barcode, type);
				await this.committedBarcode();
			},
			async onReadedTag(tag,type) {
				this.lastTag = tag;
				this.writeLog(tag, type);
				await this.committedTag();
			},
			onReaderError(data,error) {
				this.showMsgError(data, error);
				this.showMsgWarning(this.messageTitleReader, this.errorReadingReader);
			},
			changedSearch(value) {
				this.fields[this.field.search].value = value;
			},
			changedTextField(fieldId, value) {
				this.itemContents[fieldId].value = value;
				(!value || value.length == 0)? this.itemContents[fieldId].validationState = false : this.itemContents[fieldId].validationState = true
			},
			changedCheckboxField(fieldId, value) {
				this.itemContents[fieldId].value = value;
				(!value || value.length == 0)? this.itemContents[fieldId].validationState = false : this.itemContents[fieldId].validationState = true
			},
			assignedSelectField(fieldId, value) {
				this.itemContents[fieldId].value = value;
				(!value)? this.itemContents[fieldId].validationState = false : this.itemContents[fieldId].validationState = true
			},
			resetSelectField(fieldId) {
				this.itemContents[fieldId].value = null;
				this.itemContents[fieldId].validationState = false
			},
			async addPackage() {
				this.tablesData.currentPackage = null;
				await this.createPackage(this.prepareNewPackage());
				if (this.tablesData.currentPackage != null) {
					this.itemContents[this.itemContent.package].value = this.tablesData.currentPackage;
					this.itemContents[this.itemContent.package].validationState = true;
					return true;
				}
				else {
					return false;
				}
			},
			prepareNewRecord() {
				let myRecord = {
					project_rows: {
						project_id: this.tablesData.currentPick.project_id, // this.projectId
						document_id: this.tablesData.currentPick.document_id, //this.documentId
						location_id: this.tablesData.currentPick.location.id,
						item_id: this.tablesData.currentPick.item.id,
						quantity: this.itemContents[this.itemContent.quantity].value,
						external_reference: this.tablesData.currentPick.external_code,
						package_id: this.itemContents[this.itemContent.package].value.id,
					}
				};
				return myRecord;
			},
			prepareNewPackage() {
				let myRecord = {
					packages: {
						project_id: this.projectId,
						closed: false,
						shipped: false,
					}
				};
				return myRecord;
			},
			prepareUpdateRecord() {
				let myRecord = {
					project_rows: {
						quantity: this.itemContents[this.itemContent.readedQuantity].value,
					}
				};
				return myRecord;
			},
			updateItemContents(row) {
				Object.getOwnPropertyNames(row).forEach(rowField => {
					this.itemContents.forEach(pageElement => {
						if (rowField == pageElement.name) {
							pageElement.value = row[rowField];
						}
					});
				});
			},
			createCustomItemContents(pickRow) {
				this.itemContents[this.itemContent.code].value = pickRow.item.code
				this.itemContents[this.itemContent.description].value = pickRow.item.description
				this.itemContents[this.itemContent.expectedQuantity].value = pickRow.quantity
				this.itemContents[this.itemContent.expectedQuantity].furtherInfo = (pickRow.quantity_detected > 0)? ' (già inseriti: ' + parseFloat(pickRow.quantity_detected) + ')': null
				this.itemContents[this.itemContent.quantity].value = null
				//this.itemContents[this.itemContent.quantity].furtherInfo = ' / ' + (parseFloat(pickRow.quantity) - parseFloat(pickRow.quantity_detected))
				this.itemContents[this.itemContent.package].value = null
			},
			updateCustomItemContents(row) {
				this.itemContents[this.itemContent.id].value = row.project_row.id
				this.itemContents[this.itemContent.location].value = row.location.name
				this.itemContents[this.itemContent.code].value = row.item.code
				this.itemContents[this.itemContent.description].value = row.item.description
				this.itemContents[this.itemContent.expectedQuantity].value = parseInt(row.quantity)
				this.itemContents[this.itemContent.readedQuantity].value = parseInt(row.quantity_detected)
			},
			createNewPick() {
				this.itemContents.forEach(element => element.value = element.defaultValue);
				this.itemContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.newItemText;
				this.actionDbCreate = true;
				this.openModal(false);
			},
			async createNewProjectRow(tableRow) {
				if (!tableRow) {
					this.itemContents.forEach(element => element.value = element.defaultValue);
				}
				else {
					this.tablesData.currentPick = tableRow;
					await this.getPick(tableRow.id);
					this.createCustomItemContents(this.tablesData.currentPick);
				}
				this.itemContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.newItemText;
				this.actionDbCreate = true;
				this.openModal(false);
			},
			async editExistingPick(pick) {
				this.updateCustomItemContents(pick);
				this.itemContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.editItemText // + pick.item.code;
				this.actionDbCreate = false;
				this.openModal(false);
			},
			async editExistingProjectRow(projectRow) {
				this.updateCustomItemContents(projectRow);
				this.itemContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.editItemText // + projectRow.item.code;
				this.actionDbCreate = false;
				this.openModal(false);
			},
			async showExistingPick(pick) {
				this.updateCustomItemContents(pick);
				this.itemContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.showItemText // + pick.item.code;
				this.actionDbCreate = false;
				this.openModal(true);
			},
			async showExistingProjectRow(projectRow) {
				this.updateCustomItemContents(projectRow);
				this.itemContents.forEach(element => (element.required == true? (element.value == null && element.id > 0 ? element.validationState = false : element.validationState = true) : element.validationState = null));
				this.modalTitle = this.showItemText // + projectRow.item.code;
				this.actionDbCreate = false;
				this.openModal(true);
			},
			openModal(isReadOnly) {
				this.modalReadOnly = isReadOnly;
				this.isOpenModal = true;
			},
			async closeModal(saveRecord) {
				if (saveRecord == true) {
					if (this.actionDbCreate == true) {
						await this.createProjectRow(this.prepareNewRecord())
					}
					else {
						await this.updateProjectRow(this.itemContents[this.itemContent.id].value, this.prepareUpdateRecord())
					}
					await this.getAllTablesData();
				}
				this.isOpenModal = false;
			},
			async modalDismissed() {
				this.actionDbCreate == null;
			},
			changedBarcode(value) {
				this.lastBarcode = value;
			},
			async committedBarcode() {
				let foundElement = null;
				this.tablesData.picks.some(pick => {
					if (pick.item.code == this.lastBarcode) {
						foundElement = pick;
						return true;
					}
				});
				if (!foundElement) this.showMsgWarning(this.messageSearchTitle, this.messageSearchNotFound);
				else this.editExistingPick(foundElement);
			},
			changedTag(value) {
				this.lastTag = value;
			},
			async committedTag() {
				let tagElements = this.lastTag.split('|');
				let foundElement = null;

				switch (tagElements.length) {
					case 0:
						this.lastTag = null;
						this.showMsgWarning(this.messageTitleCheckTAG,this.warnInavlidTAG);
						break;
					case 1:
						this.tablesData.picks.some(pick => {
							if (pick.item.code == this.lastTag) {
								foundElement = pick;
								return true;
							}
						});
						if (!foundElement) this.showMsgWarning(this.messageSearchTitle, this.messageSearchNotFound);
						else this.editExistingPick(foundElement);
						break;
					default:
						this.lastTag = null;
						this.showMsgWarning(this.messageTitleCheckTAG,this.warnMultipleTAG);
						break;
				}
			},
			async addPackageAndPrintLabel() {
				let addPackageState = await this.addPackage();
				if (addPackageState && this.canPrint) {
					await this.getPrinter(this.printerId) //this.printerId
					if (this.tablesData.currentPrinter.is_online == true) {
						this.executePrinterAction(`printers/` + this.printerId + `?do=print_single_barcode` + `&barcode=` + this.tablesData.currentPackage.name)
						.then(() => {
							this.showMsgInfo(
								this.messageTitleInsert,
								this.successSaveMessage + "<br><br>" + this.successPrintMessage + "&nbsp" + this.barcode);
						})
						.catch(error => {
							this.checkUserSession(error, "warning");
							this.showMsgError(this.messageTitlePrint, this.errorPrintMessage);
						})
					}
					else {
						this.showMsgWarning(
								this.messageTitlePrint,
								this.warningPrinterOffline);
					}
				}
				else {
					this.showMsgInfo(
						this.messageTitleInsert,
						this.successSaveMessage);
				}
			},
			async exitProject() {
				let canExit = true;
				let myRecord = {
					document: {}
				};
				
				if (this.isOrderFinished == true) {
					if (this.isIncoming == true) myRecord.document['closed'] = true;
					if (this.isOutgoing == true) myRecord.document['delivered'] = true;
					if (this.isPicking == false) await this.updateDocument(this.documentId, myRecord);
				}
				else {
					if (this.isOrderClean != true) {
						myRecord.document['partial'] = true;
						if (this.isPicking == false) await this.updateDocument(this.documentId, myRecord);
						await this.confirmYesNo(this.confirmExitTitle, this.confirmExitMessage)
						.then((data) => { canExit = data })
					} else {
						myRecord.document['closed'] = false;
						myRecord.document['delivered'] = false;
						myRecord.document['partial'] = false;
						if (this.isPicking == false) await this.updateDocument(this.documentId, myRecord);
					}
				}

				if (canExit == true) {
					if (this.isIncoming == true) this.$router.push("/selection" + "?incoming=true&outgoing=false&picking=false");
					if (this.isOutgoing == true) this.$router.push("/selection" + "?incoming=false&outgoing=true&picking=false");
					if (this.isPicking == true) this.$router.push("/selection" + "?incoming=false&outgoing=false&picking=true");
				}
			}
		}
	});
</script>
